import { SVGProps } from "react"

const SvgMailIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.636 5.045h14.727c.904 0 1.637.733 1.637 1.637v10.636c0 .904-.733 1.636-1.636 1.636H4.636A1.635 1.635 0 013 17.318V6.682c0-.904.733-1.637 1.636-1.637v0z" />
      <path d="M3.12 6.075l7.693 5.503a2 2 0 002.325.002l7.738-5.519" />
    </g>
  </svg>
)

export default SvgMailIcon

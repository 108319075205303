import { MutableRefObject, useEffect } from "react"

const useClickOutside = (ref: MutableRefObject<any>, onClick?: () => void) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (onClick) onClick()
      }
    }

    window.addEventListener("click", handleClick)
    return () => window.removeEventListener("click", handleClick)
  }, [ref])
}

export default useClickOutside

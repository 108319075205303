import { SVGProps } from "react"

const SvgProductsIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 16.98L11.99 21M11.99 21L3 16.98M21 12l-9.01 4.02M11.99 16.02L3 12M3 7.024l8.987 4.025L21 7.024 12.013 3 3 7.024z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgProductsIcon

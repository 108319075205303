import Link from "next/link"
import { useRouter } from "next/router"
import styled, { css } from "styled-components"

import SvgProductsIcon from "@/media/icons/SvgProductsIcon"
import SvgMailIcon from "@/media/icons/SvgMailIcon"

import Container from "./Container"
import { baseTitleStyle } from "./Typo"

interface IProps {
  open: boolean
}

export default function Navigation({ open }: IProps) {
  const router = useRouter()

  return (
    <Wrapper open={open}>
      <Container>
        <Title>Navigation</Title>
        <Links>
          <LinkItem>
            <Link href="/" passHref>
              <LinkAnchor active={router.pathname === "/"}>
                <LinkIcon>
                  <SvgProductsIcon />
                </LinkIcon>
                <span>All Products</span>
              </LinkAnchor>
            </Link>
          </LinkItem>
          <LinkItem>
            <LinkAnchor href="mailto:support@fintory.com">
              <LinkIcon>
                <SvgMailIcon />
              </LinkIcon>
              <span>Support</span>
            </LinkAnchor>
          </LinkItem>
        </Links>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ open: boolean }>`
  z-index: 6;
  transition: height ease 0.3s;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: ${({ open }) => (open ? 100 : 0)}vh;
  padding-top: 59px;
  background: ${({ theme }) => theme.background};
`

const Title = styled.h4`
  font-family: ${({ theme }) => theme.fonts.title};
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.foreground};
  margin-top: 32px;
`

const Links = styled.ul`
  margin-top: 28px;
`

const LinkItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

const textStyle = css`
  ${baseTitleStyle}
  font-size: 18px;
  line-height: 18px;
`

const LinkAnchor = styled.a<{ active?: boolean }>`
  transition: opacity ease 64ms;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  ${textStyle}
  display: inline-flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`

const LinkIcon = styled.div`
  margin-right: 16px;
`

import Head from "next/head"

interface SeoProps {
  title?: string
  description?: string
  image?: string
}

enum Position {
  LEADING,
  TRAILING
}

// 0 = leading ; 1 = trailing
const position: Position = Position.TRAILING
const pageName = "Fintory Products"
const separator = "-"

export const Seo = ({ title, description, image }: SeoProps) => {
  const composedTitle = [
    // @ts-expect-error
    position === Position.LEADING
      ? pageName + (title ? " " + separator + " " : "")
      : "",
    title,
    position === Position.TRAILING
      ? (title ? " " + separator + " " : "") + pageName
      : ""
  ].join("")

  return (
    <Head>
      <title>{composedTitle}</title>
      <meta name="title" content={composedTitle} />
      <meta name="og:title" content={composedTitle} />
      {description && <meta name="description" content={description} />}
      {description && <meta name="og:description" content={description} />}
      {image && (
        <meta
          name="og:image"
          content={"https://" + process.env.NEXT_PUBLIC_APP_URL + image}
        />
      )}
    </Head>
  )
}

import { SVGProps } from "react"

const Logo = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    width={162}
    height={24}
    viewBox="0 0 162 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.185 19.316a1.047 1.047 0 01-1.481 0L11.65 5.262a1.047 1.047 0 010-1.481l2.603-2.604a1.047 1.047 0 011.482 0L29.788 15.23a1.047 1.047 0 010 1.481l-2.604 2.604zm-11.896.058a1.047 1.047 0 001.481 0l2.604-2.604a1.047 1.047 0 000-1.48l-8.876-8.877a1.047 1.047 0 00-1.48 0L6.412 9.017a1.047 1.047 0 000 1.481l8.876 8.876zm-10.415.058a1.047 1.047 0 001.481 0l2.604-2.604a1.047 1.047 0 000-1.481L5.262 11.65a1.047 1.047 0 00-1.481 0l-2.604 2.603a1.047 1.047 0 000 1.482l3.697 3.697zM43.509 10.377V7.52h-2.373V5.723c0-.99.414-1.567 1.152-1.567.253 0 .507.07.714.184l1.06-2.741c-.576-.254-1.267-.392-2.05-.392-2.236 0-4.148 1.567-4.148 4.285V7.52h-1.82v2.857h1.82v8.386h3.272v-8.386h2.373zm5.016 8.386V7.52h-3.294v11.244h3.294zM45.316 4.566c0 .867.73 1.596 1.596 1.596.868 0 1.597-.729 1.597-1.596 0-.868-.729-1.596-1.596-1.596-.868 0-1.596.728-1.596 1.596zm8.992 7.61c.047-1.498 1.13-2.282 2.097-2.282 1.083 0 2.074.806 2.074 2.373v6.498h3.248v-6.682c0-3.018-1.75-4.838-4.078-4.838-1.544 0-2.672.76-3.41 1.82.046-.323.07-.6.07-.784v-.76h-3.226v11.244h3.225v-6.59zm9.154-4.655v2.995h1.566v8.248h3.226v-8.248h1.59V7.52h-1.59V3.511h-3.226V7.52h-1.567zm13.3 11.45c-3.295 0-5.875-2.51-5.875-5.851s2.58-5.852 5.875-5.852 5.875 2.51 5.875 5.852c0 3.34-2.58 5.852-5.875 5.852zm0-2.856c1.636 0 2.719-1.267 2.719-2.995 0-1.705-1.083-2.972-2.72-2.972-1.658 0-2.74 1.267-2.74 2.972 0 1.728 1.128 2.995 2.74 2.995zm10.835-2.903c0-1.843 1.336-2.811 3.018-2.811V7.244c-1.474 0-2.488.921-3.133 2.142.069-.438.115-.852.115-1.106v-.76h-3.226v11.244h3.226V13.21zm5.477 10.272h3.364L103.68 7.52h-3.41l-2.258 5.253c-.207.484-.415 1.106-.415 1.152-.023-.069-.184-.691-.368-1.152L95.062 7.52h-3.387l4.193 10-2.794 5.963z"
      fill="currentColor"
    />
    <g opacity={0.3} fill="currentColor">
      <path d="M110.748 15.92c1.386 2.222 3.366 3.322 5.984 3.322 3.3 0 5.126-2.024 5.126-4.224 0-1.98-1.276-3.52-3.784-4.378l-2.244-.77c-1.848-.616-2.618-1.584-2.618-2.794 0-1.386 1.166-2.618 3.212-2.618 1.496 0 2.926.748 3.894 1.98l1.034-.968c-1.232-1.518-2.882-2.332-4.906-2.332-2.838 0-4.664 1.87-4.664 3.982 0 1.848 1.166 3.278 3.432 4.048l2.288.77c2.024.704 2.882 1.694 2.882 3.124 0 1.54-1.298 2.838-3.608 2.838-2.112 0-3.74-.924-4.884-2.838l-1.144.858zM130.169 19.044l-.176-1.232a3.836 3.836 0 01-1.1.176c-1.32 0-2.156-.682-2.156-2.552v-6.27h3.432V7.978h-3.432V4.414h-1.386v3.564h-1.892v1.188h1.892v6.248c0 2.464 1.254 3.828 3.454 3.828.528 0 .99-.088 1.364-.198zM131.57 13.478c0 3.432 2.244 5.764 5.258 5.764s5.258-2.332 5.258-5.764c0-3.41-2.244-5.764-5.258-5.764s-5.258 2.354-5.258 5.764zm1.43 0c0-2.662 1.54-4.51 3.828-4.51s3.828 1.848 3.828 4.51c0 2.684-1.54 4.51-3.828 4.51S133 16.162 133 13.478zM146.213 19v-5.962c0-2.574 1.694-3.96 3.916-3.982l.044-1.342c-1.914 0-3.454.968-4.026 2.442V7.978h-1.32V19h1.386zM161.039 16.888l-1.078-.77c-.88 1.166-2.112 1.87-3.41 1.87-2.222 0-3.762-1.738-3.85-4.29h8.558v-.418c0-3.212-1.914-5.566-4.774-5.566-2.948 0-5.192 2.354-5.192 5.764 0 3.432 2.244 5.764 5.258 5.764 1.914 0 3.432-.946 4.488-2.354zm-4.576-7.92c1.958 0 3.146 1.474 3.344 3.608h-7.04c.286-2.2 1.716-3.608 3.696-3.608z" />
    </g>
  </svg>
)

export default Logo

import styled from "styled-components"

import { media } from "@/utils/styling"

const Container = styled.div`
  width: 93vw;
  margin: 0 auto;
  max-width: 1200px;

  ${media.xs} {
    width: auto;
    padding: 0 16px;
  }
`

export default Container

import styled, { css } from "styled-components"

import { media } from "@/utils/styling"

export const baseTitleStyle = css`
  font-family: ${({ theme }) => theme.fonts.title};
  color: ${({ theme }) => theme.foreground};
`

export const H1 = styled.h1`
  ${baseTitleStyle}
  font-weight: 700;
  font-size: 58px;
  line-height: 78px;

  ${media.xs} {
    font-size: 28px;
    line-height: 40px;
  }
`

export const H2 = styled.h2`
  ${baseTitleStyle}
  font-weight: 500;
  font-size: 38px;
  line-height: 35px;

  ${media.xs} {
    font-size: 28px;
    line-height: 28px;
  }
`

export const H3 = styled.h3`
  ${baseTitleStyle}
  font-weight: 500;
  font-size: 18px;
`

export const baseTextStyle = css`
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.highContrast};
`

export const P = styled.p`
  ${baseTextStyle}
`

export const Span = styled.span`
  ${baseTextStyle}
`

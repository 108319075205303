import styled, { css } from "styled-components"

const PageLayout = styled.main<{ narrow?: boolean }>`
  ${props =>
    props.narrow &&
    css`
      flex: 1 1 auto;
      max-width: 384px;
      margin: 0 auto;
    `}
`

export default PageLayout

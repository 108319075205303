import styled from "styled-components"

const Hamburger = styled.button<{ open?: boolean }>`
  cursor: pointer;
  position: relative;
  height: 32px;
  width: 32px;
  padding: 0;
  border: 0;
  background: transparent;

  &::after,
  &::before {
    content: "";
    transition: transform ease 0.2s;
    transform-origin: center;
    position: absolute;
    left: 6px;
    width: 20px;
    height: 2px;
    background: ${({ theme }) => theme.foreground};
  }

  &::before {
    top: 12px;

    transform: rotate(${({ open }) => (open ? 45 : 0)}deg)
      translateY(${({ open }) => (open ? 3 : 0)}px)
      translateX(${({ open }) => (open ? 2 : 0)}px);
  }

  &::after {
    top: 19px;

    transform: rotate(${({ open }) => (open ? -45 : 0)}deg)
      translateY(${({ open }) => (open ? -3 : 0)}px)
      translateX(${({ open }) => (open ? 2 : 0)}px);
  }
`

export default Hamburger

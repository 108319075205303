export const mediaQuery = {
  xxl: "(max-width: 1632px)",
  xl: "(max-width: 1440px)",
  lg: "(max-width: 1164px)",
  md: "(max-width: 920px)",
  sm: "(max-width: 768px)",
  xs: "(max-width: 580px)"
}

export const media = {
  xxl: "@media screen and (max-width: 1632px)",
  xl: "@media screen and (max-width: 1440px)",
  lg: "@media screen and (max-width: 1164px)",
  md: "@media screen and (max-width: 920px)",
  sm: "@media screen and (max-width: 768px)",
  xs: "@media screen and (max-width: 580px)"
}

export const rgba = (hex: string, a: number) => {
  let parsed = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  const c = parsed
    ? {
        r: parseInt(parsed[1], 16),
        g: parseInt(parsed[2], 16),
        b: parseInt(parsed[3], 16)
      }
    : {
        r: 0,
        b: 0,
        c: 0
      }

  return `rgba(${c.r}, ${c.g}, ${c.b}, ${a})`
}

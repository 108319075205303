import Link from "next/link"
import { useRouter } from "next/router"
import { useState } from "react"
import styled, { css } from "styled-components"

import Logo from "@/media/Logo"

import { media } from "@/utils/styling"

import SvgProductsIcon from "@/media/icons/SvgProductsIcon"
import SvgMailIcon from "@/media/icons/SvgMailIcon"
import SvgSigninIcon from "@/media/icons/SvgSigninIcon"

import Container from "./Container"
import Hamburger from "./Hamburger"
import Navigation from "./Navigation"
import FileAccess from "./FileAccess"
import { baseTitleStyle } from "./Typo"

export default function Navbar() {
  const [open, setOpen] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)

  const router = useRouter()

  return (
    <Wrapper>
      <Container>
        <Layout>
          <Left>
            <Link href="/" passHref>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            </Link>
            <Links>
              <LinkItem>
                <Link href="/" passHref>
                  <LinkAnchor active={router.pathname === "/"}>
                    <LinkIcon>
                      <SvgProductsIcon />
                    </LinkIcon>
                    <span>All Products</span>
                  </LinkAnchor>
                </Link>
              </LinkItem>
              <LinkItem>
                <LinkAnchor href="mailto:support@fintory.com">
                  <LinkIcon>
                    <SvgMailIcon />
                  </LinkIcon>
                  <span>Support</span>
                </LinkAnchor>
              </LinkItem>
            </Links>
          </Left>
          <div style={{ position: "relative" }}>
            <SigninButton
              active={popupOpen}
              onClick={() => setPopupOpen(open => !open)}
            >
              <SvgSigninIcon />
              <span style={{ marginLeft: 12 }}>Login</span>
            </SigninButton>
            {popupOpen && <FileAccess onClose={() => setPopupOpen(false)} />}
          </div>
          <StyledHamburger open={open} onClick={() => setOpen(open => !open)} />
        </Layout>
      </Container>
      <Navigation {...{ open }} />
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  z-index: 6;
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.lowContrast};
  background: ${({ theme }) => theme.background};
`

const Layout = styled.div`
  z-index: 7;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;

  ${media.sm} {
    height: 59px;
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const LogoWrapper = styled.a`
  color: ${({ theme }) => theme.foreground};
  margin-right: 76px;

  ${media.sm} {
    height: 20px;
  }

  svg {
    width: auto;
    height: 100%;
  }
`

const Links = styled.ul`
  display: inline-flex;
  align-items: center;

  ${media.sm} {
    display: none;
  }
`

const LinkItem = styled.li`
  &:not(:last-child) {
    margin-right: 40px;
  }
`

const textStyle = css`
  ${baseTitleStyle}
  font-size: 14px;
  line-height: 16px;
`

const LinkAnchor = styled.a<{ active?: boolean }>`
  transition: opacity ease 64ms;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  ${textStyle}
  display: inline-flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`

const LinkIcon = styled.div`
  margin-right: 12px;
`

const SigninButton = styled.button<{ active?: boolean }>`
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  outline: none;
  border: 0;
  padding: 4px 8px;
  ${textStyle}
  display: inline-flex;
  align-items: center;
  margin: 0 -8px;
  background: transparent;

  &:hover {
    opacity: 1;
  }

  ${media.sm} {
    display: none;
  }
`

const StyledHamburger = styled(Hamburger)`
  display: none;

  ${media.sm} {
    display: block;
  }
`

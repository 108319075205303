import { SVGProps } from "react"

const SvgFileAccessIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 11H4a2 2 0 00-1.952 2.434l1.11 5A2 2 0 005.113 20h13.646a2 2 0 001.941-1.518l1.241-5A2.002 2.002 0 0020 11z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 11.271V8.94a2 2 0 00-2-2h-6.47a1 1 0 01-.828-.437l-1.405-2.066A1 1 0 009.471 4H5a2 2 0 00-2 2v5.271"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgFileAccessIcon

import { FormEvent, useRef, useState } from "react"
import styled from "styled-components"

import useClickOutside from "@/utils/hooks/useClickOutside"

import SvgFileAccessIcon from "@/media/icons/SvgFilesAccessIcon"

import { baseTextStyle, H3, P } from "./Typo"

interface IProps {
  onClose: () => void
}

export default function FileAccess({ onClose }: IProps) {
  const [step, setStep] = useState(0)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const data = new FormData(e.currentTarget)
    const email = data.get("email")

    if (!email) return

    setStep(1)

    await fetch(`/api/sign-in?email=${email}`)
  }

  useClickOutside(wrapperRef, onClose)

  return (
    <Wrapper ref={wrapperRef}>
      <div style={{ overflow: "hidden" }}>
        <Views
          style={{
            transform: `translateX(-${step * 100}%)`
          }}
        >
          <View active={0 === step}>
            <TitleWrapper>
              <IconWrapper>
                <SvgFileAccessIcon />
              </IconWrapper>
              <Title>Access Files</Title>
            </TitleWrapper>
            <Desc>
              Please enter the email address you have already purchased with to
              download the files again.
            </Desc>
            <form onSubmit={handleSubmit}>
              <InputWrapper>
                <Input
                  type="email"
                  name="email"
                  placeholder="Enter Email Address"
                  required
                />
              </InputWrapper>
            </form>
          </View>
          <View active={1 === step}>
            <TitleWrapper>
              <IconWrapper>
                <SvgFileAccessIcon />
              </IconWrapper>
              <Title>Please check your inbox</Title>
            </TitleWrapper>
            <Desc>
              If you have already purchased from us, we have sent you an email
              with a unique link that you can use to access your files.
            </Desc>
          </View>
        </Views>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  padding: 24px;
  width: 320px;
  box-shadow: 0px 18px 85px rgba(0, 0, 0, 0.06),
    0px 7.51997px 35.511px rgba(0, 0, 0, 0.0431313),
    0px 4.02054px 18.9859px rgba(0, 0, 0, 0.0357664),
    0px 2.25388px 10.6433px rgba(0, 0, 0, 0.03),
    0px 1.19702px 5.65259px rgba(0, 0, 0, 0.0242336),
    0px 0.498106px 2.35217px rgba(0, 0, 0, 0.0168687);
  background: ${({ theme }) => theme.background};
`

const Views = styled.div`
  transition: transform ease-in-out 0.4s;
  display: flex;
  align-items: baseline;
`

const View = styled.div<{ active: boolean }>`
  transition: max-height ease 0.6s;
  min-width: 100%;
  max-height: ${({ active }) => (active ? "220px" : "0px")};
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.foreground};
`

const Title = styled(H3)``

const Desc = styled(P)`
  font-size: 14px;
  line-height: 22px;
  margin-top: 8px;
`

const InputWrapper = styled.div`
  position: relative;
  margin-top: 24px;

  &::after {
    content: "";
    position: absolute;
    top: 12px;
    right: 16px;
    height: 16px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.98633 7.98657H12.9797' stroke='%23323232' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.98828 3.99194L13.0129 7.99994L8.98828 12.0079' stroke='%23323232' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
`

const Input = styled.input`
  outline: none;
  ${baseTextStyle}
  font-size: 14px;
  color: ${({ theme }) => theme.foreground};
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.lowContrast};
  background: transparent;
`
